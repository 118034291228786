<div id="dropArea">
  <div fxLayout="row wrap" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
       cdkDragBoundary=".cdk-overlay-container" cdkDragHandle class="dialog-drag">
    <h2 class="profile-widget-heading" *ngIf="isEdit === false">Profile</h2>
    <h2 class="profile-widget-heading" *ngIf="isEdit === true">Edit Profile</h2>
    <div fxFlex></div>
    <button mat-icon-button matTooltip="Click here to close the popup"
            (click)="onCancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="userProfileForm" autocomplete="off">
    <div fxLayout="row wrap" fxLayoutAlign="start">
      <div class="profile-section-fields">
        <ejs-textbox placeholder="First Name*" floatLabelType="Auto"
                     formControlName="firstName" (input)='firstNameInputHandler($event)' appInputRestriction>
        </ejs-textbox>
        <mat-error *ngIf="userProfileForm.get('firstName').hasError('required') && userProfileForm.get('firstName').touched">
          <small>Required</small>
        </mat-error>
      </div>
      <div class="profile-section-fields">
        <ejs-textbox placeholder="Last Name*" floatLabelType="Auto"
                     formControlName="lastName" (input)='lastNameInputHandler($event)' appInputRestriction>
        </ejs-textbox>
        <mat-error *ngIf="userProfileForm.get('lastName').hasError('required') && userProfileForm.get('lastName').touched">
          <small>Required</small>
        </mat-error>
      </div>
      <div class="profile-section-fields">
        <ejs-textbox placeholder="User Name*" floatLabelType="Auto"
                     formControlName="username">
        </ejs-textbox>
      </div>
      <div class="profile-section-fields">
        <ejs-textbox type="number" placeholder="Phone Number" floatLabelType="Auto"
                     formControlName="phoneNumber" (input)='phoneNumberInputHandler($event)' min="0">
        </ejs-textbox>
      </div>
      <div class="profile-section-fields">
      <ejs-dropdownlist #timeZoneDropdown [dataSource]="timezoneList" floatLabelType='Auto' placeholder="Timezone*"
                        [sortOrder]="generalSortOrder" [fields]="timezoneFields" popupHeight='250px'
                        [allowFiltering]='true' [filterBarPlaceholder]="'Search Timezone'"
                        class="timezone-dropdown" formControlName="timezone" (filtering)="searchTimezone($event)">
        <ng-template #noRecordsTemplate>
          <span class='norecord'>Loading Timezones...</span>
        </ng-template>
      </ejs-dropdownlist>
        <mat-error *ngIf="userProfileForm.get('timezone').hasError('required') && userProfileForm.get('timezone').touched">
          <small>Select Time Zone</small>
        </mat-error>
      </div>
      <div class="profile-section-fields">
        <ejs-textbox placeholder="Email Address*" floatLabelType="Auto"
                     formControlName="email">
        </ejs-textbox>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="3px" fxLayoutAlign="end" class="action-button-section">
      <button data-testid="Yes" mat-raised-button color="primary" type="submit"
              *ngIf="isEdit === false" (click)="onEditProfile(isEdit)">
        Edit
      </button>
      <button data-testid="Yes" mat-raised-button color="primary" type="submit" (click)="updateProfile(isEdit)"
              *ngIf="isEdit === true">
        Update Info
      </button>
      <button data-testid="No" mat-raised-button type="submit" (click)="onCancel()">Cancel</button>
    </div>
  </form>
</div>
