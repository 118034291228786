import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";
import { ExtensionService } from "./athena-one-integration/shared/service/extension.service";
import { Router } from "@angular/router";
import { SessionService } from "./session/session.service";
import { EXTENSION_MESSAGE } from "./athena-one-integration/shared/constants/scraped-data.constants";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {

  setDataValue(event: MessageEvent) {
    const data = event.data;
    this.extensionService.publishScrappedData(data)
  }

  subscribeToDataValue() {
    this.extensionService.subscribeToScrappedData().subscribe((data) => {
      if (data?.type === EXTENSION_MESSAGE.SCRAPPED_DATA || data?.type === EXTENSION_MESSAGE.LOGOUT || data === undefined) {
        localStorage.clear()
        localStorage.setItem('isExt', '1')
        this.router.navigate(['/athena-one'])
      }
    })
  }
  constructor(translate: TranslateService, private titleService: Title, private extensionService: ExtensionService, private router: Router) {
    this.setTitle("EPP");
    translate.addLangs(["en", "fr"]);
    translate.setDefaultLang("en");

    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : "en");
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit(): void {
    window.addEventListener('message', this.setDataValue.bind(this));
    this.subscribeToDataValue()
  }
}
