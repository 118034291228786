import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(private cookieService: CookieService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const csrftoken = this.cookieService.get(environment.CSRF_COOKIE_NAME);
    const accessToken = sessionStorage.getItem("access_token");
    if(csrftoken !== null && csrftoken !== undefined && accessToken !== null && accessToken !== undefined){
      const headers = new HttpHeaders({
        "Content-Type": "application/json",
        // "Access-Control-Allow-Credentials": "true",
        Accept: "application/json",
        responseType: "arraybuffer",
        Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        "X-CSRFToken": csrftoken
      });

      const cloneReq = req.clone({headers,withCredentials: true});
      return next.handle(cloneReq);
    }else{
      const headers = new HttpHeaders({
        "Content-Type": "application/json",
        // "Access-Control-Allow-Credentials": "true",
        Accept: "application/json",
        responseType: "arraybuffer",
        // Authorization: "Basic " + btoa("varun:POaccount@9114"),
      });

      const cloneReq = req.clone({headers,withCredentials: true});
      return next.handle(cloneReq);
    }
  }
}
