import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor(private activeRoute: ActivatedRoute) { }

  error$: Observable<Params>

  ngOnInit(): void {
    this.error$ = this.activeRoute.queryParams
  }

}
