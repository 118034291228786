<div cdkDrag cdkDragRootElement=".cdk-overlay-pane"
     cdkDragBoundary=".cdk-overlay-container" cdkDragHandle class="dialog-drag">
  <div fxLayout="row wrap">
    <h2 class="reassign-supervisor-heading">Reassign Supervisor</h2>
    <div fxFlex></div>
    <span>
      <button mat-icon-button matTooltip="Click here to close the popup"
              (click)="onCancel()">
       <mat-icon>close</mat-icon>
      </button>
    </span>
  </div>
</div>
<p>
  To unmark user as a supervisor, please reassign reporting user to different supervisor from the dropdown.
</p>
<div fxLayout="row wrap">
  <ejs-dropdownlist #supervisorDropdown floatLabelType='Always' [dataSource]="supervisorList"
                    [fields]='supervisorFields' [query]='supervisorQuery'
                    [placeholder]='supervisor' [allowFiltering]='true' (filtering)="searchSupervisors($event)"
                    [filterBarPlaceholder]="'Search Supervisor'" (select)="getSelectedSupervisor($event)"
                    [sortOrder]="generalSortOrder" [popupHeight]='height' class="supervisor-dropdown">
    <ng-template #noRecordsTemplate>
      <span class='norecord'>Loading Supervisors...</span>
    </ng-template>
  </ejs-dropdownlist>
</div>

<br>

<div fxLayout="row" fxLayoutGap="3px" fxLayoutAlign="end">
  <button data-testid="Yes" mat-raised-button color="primary" cdkFocusInitial
          type="submit" (click)="reassignSupervisor($event)" >
    Reassign
  </button>
  <button data-testid="No" mat-raised-button type="submit" (click)="onCancel()">
    Cancel
  </button>
</div>
