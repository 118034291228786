import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {DataService} from "../common/services/data.service";
// import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { ConcurrentDataService } from "src/app/shared/shared-module/shared-service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {IChart, IProjectConfiguration} from "../coding/Models/chartv2";
import { FieldSettingsModel, ToolbarSettingsModel } from '@syncfusion/ej2-dropdowns';
import { ActivatedRoute } from '@angular/router';
import { MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { CoderRole, QAL1Role, QAL2Role, QAL3Role } from '../common/settings/settings';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-code-comments-select-dialog',
  templateUrl: './code-comments-select-dialog.component.html',
  styleUrls: ['./code-comments-select-dialog.component.scss']
})
export class CodeCommentsSelectDialogComponent implements OnInit {

  public commentForm: FormGroup;
  public projectConfigurations: IProjectConfiguration;
  public chartData: IChart;
  public codeCommentField: object = { value: "id", text: "comment" };
  public codeCommentsList = [];
  public generalSortOrder = "Ascending";
  public modeOfSelection: string;
  public codeStatusList = [];
  public dataSource = [];
  public roleId: number;
  public coderRoleId = CoderRole;
  public QA1RoleId = QAL1Role;
  public QA2RoleId = QAL2Role;
  public QA3RoleId = QAL3Role;

  public fields: FieldSettingsModel = { text: 'Name'};
    public toolbarSettings: ToolbarSettingsModel = { items: ['moveTo', 'moveFrom', 'moveAllTo', 'moveAllFrom']};
    public noRecordsTemplate = '<div class= "e-list-nrt"><span>NO DATA AVAILABLE</span></div>'

    source = [ 'Pawn', 'Rook', 'Knight', 'Bishop', 'Queen', 'King' ];
	  target = [];
  public codeCommentsValues = [];

  constructor(
    public snackBar: MatSnackBar,
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<CodeCommentsSelectDialogComponent>,
    private formBuilder: FormBuilder, private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.modeOfSelection = "CheckBox";
    this.commentForm = this.buildcodeCommentForm();
    this.codeCommentList();
  }

  buildcodeCommentForm(): FormGroup {
    return this.formBuilder.group({
      codeComments: new FormControl(null, [Validators.required])
    });
}

  codeCommentList() {
    this.codeCommentsList = this.data?.comment;
    this.codeCommentsValues = this.data?.commentValue;
    this.commentForm.get('codeComments').setValue(this.codeCommentsValues);
    this.roleId = this.data?.role;
  }

  cancelComments(){
    this.dialogRef.close();
  }

  submitCommentsChart(){
    this.dialogRef.close(this.commentForm.get('codeComments').value);
  }
}
