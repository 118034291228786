<form [formGroup]="commentForm" autocomplete="off">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane"
         cdkDragBoundary=".cdk-overlay-container" cdkDragHandle class="dialog-drag">
      <div fxLayout="row wrap">
        <h2 class="comments-heading">{{roleId == coderRoleId ? 'Code Comment' : roleId == QA1RoleId ? 'L1 Audit Comment' : roleId == QA2RoleId ? 'L2 Audit Comment' : 'L3 Audit Comment'}}</h2>
        <div fxFlex></div>
        <span>
        <button mat-icon-button matTooltip="Click here to close the popup"
                (click)="cancelComments()">
         <mat-icon>close</mat-icon>
        </button>
      </span>
      </div>
    </div>
  <div>
    <ejs-multiselect #codeCommentForCDVAudit [allowFiltering]='true'
                     [dataSource]='codeCommentsList' [fields]='codeCommentField'
                     [mode]='modeOfSelection'
                     [placeholder]="roleId == coderRoleId ? 'Code Comment' : roleId == QA1RoleId ? 'L1 Audit Comment' : roleId == QA2RoleId ? 'L2 Audit Comment' : 'L3 Audit Comment'"
                     [showDropDownIcon]='true'
                     [showSelectAll]="true" [sortOrder]="generalSortOrder"
                     appECPNativeElementInjector
                     class="enableToolTip CommentDropDownPlaceholderAsPerRole" formControlName="codeComments" popupWidth='450px'
                     width="450">
      <ng-template #itemTemplate let-data>
        <span title="{{data.comment}}">{{ data.comment }}</span>
      </ng-template>
    </ejs-multiselect>
  </div>
    <br>
  <br>

    <div fxLayout="row wrap " fxLayoutGap="3px" fxLayoutAlign="end">
      <button data-testid="Submit" mat-raised-button color="primary" (click)="submitCommentsChart()">Submit</button>
    </div>
  </form>

