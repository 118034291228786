import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {API_CURRENT_VERSION, EndPointBaseUrl} from "../../../common/settings/settings";
import {CommunicatorService} from "../../../common/services/communicator.service";
import {NotificationService} from "../../../common/services/notification.service";
import {ErrorMessageService} from "../../../common/services/error-message.service";
import {SelectClientService} from "../../../client/management/select-client/select-client.service";

@Injectable({
  providedIn: "root"
})
export class ListProjectsService {
  public projectListData = new Subject<any>();
  public projectListData$ = this.projectListData.asObservable();
  public projectListOfSelectedClient = new Subject<any>();
  public projectListOfSelectedClient$ = this.projectListOfSelectedClient.asObservable();
  public projectList;
  public projectListAccordingToRole;
  public projectListDataAccordingToRole = new Subject<any>();
  public projectListDataAccordingToRole$ = this.projectListDataAccordingToRole.asObservable();
  public projectListAccordingToFilter;
  public projectListDataAccordingToFilter = new Subject<any>();
  public projectListDataAccordingToFilter$ = this.projectListDataAccordingToFilter.asObservable();
  public favoriteProject = new Subject<any>();
  public favoriteProject$ = this.favoriteProject.asObservable();
  public projectListAccordingToPermission;
  public projectListDataAccordingToPermission = new Subject<any>();
  public projectListDataAccordingToPermission$ = this.projectListDataAccordingToPermission.asObservable();
  public projectListOfClient;
  public selectedClientProjectList = new Subject<any>();
  public selectedClientProjectList$ = this.selectedClientProjectList.asObservable();

  constructor(private communicatorServiceObject: CommunicatorService,
              public notificationService: NotificationService,
              public errorMessageService: ErrorMessageService,
              public selectClientService: SelectClientService) {

    this.selectClientService.selectedClientData$.subscribe(data => {
      if (data !== null && data !== undefined) {
        this.projectList = [];
        this.projectListAccordingToRole = [];
        this.projectListAccordingToFilter = [];
      }
    });
  }

  getProjectListOfSelectedClient(userId, clientId, status, ordering?) {
    let queryParams = {};
    if (userId !== null && userId !== undefined) {
      queryParams['userId'] = userId;
    }
    if (clientId !== null && clientId !== undefined) {
      queryParams['clientId'] = clientId;
    }
    queryParams['status'] = status;
    if (ordering !== null && ordering !== undefined) {
      queryParams['ordering'] = ordering;
    }
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/projects" + queryString).subscribe(data => {
        if (data !== null && data !== undefined) {
          this.projectList = data.results;
          this.projectListOfSelectedClient.next(this.projectList);
        }
      }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
      }
    );
  }

  getAllProjectList(clientId, status) {
    let queryParams = {};
    if (clientId !== null && clientId !== undefined) {
      queryParams['clientId'] = clientId;
    }
    if (status !== null && status !== undefined) {
      queryParams['status'] = status;
    }
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    const projectListObs$ = this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/projects" + queryString);

    /*    projectListObs$.subscribe(data => {
            if (data !== null && data !== undefined) {
              this.projectList = data.results;
              this.projectListOfSelectedClient.next(this.projectList);
            }
          }, error => {
            const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBar(error);
            this.notificationService.showError(manipulatedErrorMessage);
          }
        );*/
    return projectListObs$;
  }

  getProjectList(clientId, status) {
    let queryParams = {};
    if (clientId !== null && clientId !== undefined) {
      queryParams['clientId'] = clientId;
    }
    if (status !== null && status !== undefined) {
      queryParams['status'] = status;
    }
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/projects" + queryString).subscribe(data => {
        if (data !== null && data !== undefined) {
          this.projectList = data.results;
          this.projectListData.next(this.projectList);
        }
      }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
      }
    );
  }

  getProjectListAccordingToRole(userId, clientId, roleId, status) {
    let queryParams = {};
    if (userId !== null && userId !== undefined) {
      queryParams['userId'] = userId;
    }
    if (clientId !== null && clientId !== undefined) {
      queryParams['clientId'] = clientId;
    }
    if (roleId !== null && roleId !== undefined) {
      queryParams['roleId'] = roleId;
    }
    queryParams['status'] = status;
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/projects" + queryString).subscribe(data => {
        if (data !== null && data !== undefined) {
          this.projectListAccordingToRole = data.results;
          this.projectListDataAccordingToRole.next(this.projectListAccordingToRole);
        }
      }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
      }
    );
  }

  getProjectsListForTiles(userId, clientId, roleId, status, ordering) {
    let queryParams = {};
    if (userId !== null && userId !== undefined) {
      queryParams['userId'] = userId;
    }
    if (clientId !== null && clientId !== undefined) {
      queryParams['clientId'] = clientId;
    }
    if (roleId !== null && roleId !== undefined) {
      queryParams['roleId'] = roleId;
    }
    if (ordering !== null && ordering !== undefined) {
      queryParams['ordering'] = ordering;
    }
    queryParams['status'] = status;
    queryParams['skip'] = 0;
    queryParams['take'] = 12;
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/projects" + queryString).subscribe(data => {
        if (data !== null && data !== undefined && data.results.length > 0) {
          this.projectListAccordingToFilter = data.results;
          this.projectListDataAccordingToFilter.next(this.projectListAccordingToFilter);
        }
      }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
      }
    );
  }

  pinUnpinProject(clientId, projectId, favouriteProject) {
    const projectData = {};
    if (favouriteProject === true) {
      projectData['favouriteProject'] = 1;
    }
    if (favouriteProject === false) {
      projectData['favouriteProject'] = 0;
    }
    let queryParams = {};
    if (clientId !== null && clientId !== undefined) {
      queryParams['clientId'] = clientId;
    }
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    this.communicatorServiceObject.patchData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/projects/" + projectId + queryString, JSON.stringify(projectData)).subscribe(data => {
        if (data !== null && data !== undefined) {
          this.favoriteProject.next(data);
        }
      }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
      }
    );
  }

  getProjectListAccordingToPermission(status, resourceId) {
    let queryParams = {};
    if (resourceId !== null && resourceId !== undefined) {
      queryParams['resourceId'] = resourceId;
    }
    if (status !== null && status !== undefined) {
      queryParams['status'] = status;
    }
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/projects" + queryString).subscribe(data => {
        if (data !== null && data !== undefined) {
          this.projectListAccordingToPermission = data.results;
          this.projectListDataAccordingToPermission.next(this.projectListAccordingToPermission);
        }
      }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
      }
    );
  }

  getClientIdFromProjectList(data) {
    if (data !== null && data !== undefined && data.length > 0) {
      const clientArr = [];
      for (const dataItem in data) {
        if (data[dataItem].client !== null && data[dataItem].client !== undefined) {
          clientArr.push(data[dataItem].client);
        }
      }
      return clientArr;
    }
  }

  getSelectedClientProjectList(status, resourceId, clientId) {
    let queryParams = {};
    if (status !== null && status !== undefined) {
      queryParams['status'] = status;
    }
    if (resourceId !== null && resourceId !== undefined) {
      queryParams['resourceId'] = resourceId;
    }
    if (clientId !== null && clientId !== undefined) {
      queryParams['clientId'] = clientId;
    }
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/projects" + queryString).subscribe(data => {
        if (data !== null && data !== undefined) {
          this.projectListOfClient = data.results;
          this.selectedClientProjectList.next(this.projectListOfClient);
        }
      }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
      }
    );
  }
}
