import { Injectable } from "@angular/core";
import {API_CURRENT_VERSION, Concurrent, EndPointBaseUrl} from "../../../common/settings/settings";
import {CommunicatorService} from "../../../common/services/communicator.service";
import {NotificationService} from "../../../common/services/notification.service";
import {ErrorMessageService} from "../../../common/services/error-message.service";
import {BehaviorSubject, Subject} from "rxjs";
import {IProject} from "../../../coding/Models/chart";
import {HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class SelectProjectService {
  public lobDetails = new BehaviorSubject<any>(null);
  lobDetails$ = this.lobDetails.asObservable();
  public lobList = [];
  private roleList = [];
  public roleDetails = new BehaviorSubject<any>([]);
  roleDetails$ = this.roleDetails.asObservable();
  public POSList: Array<any>;
  public POSDetails = new BehaviorSubject<any>([]);
  POSDetails$ = this.POSDetails.asObservable();
  public noteTypeProjectList = {};
  public noteTypeDetails = new BehaviorSubject<any>([]);
  noteTypeDetails$ = this.noteTypeDetails.asObservable();
  public reviewerResponseList = {};
  public reviewerResponseListDetails = new BehaviorSubject<any>([]);
  reviewerResponseListDetails$ = this.reviewerResponseListDetails.asObservable();
  public finalResponseList = {};
  public finalResponseListDetails = new BehaviorSubject<any>([]);
  finalResponseListDetails$ = this.finalResponseListDetails.asObservable();
  public projectConfig = {};
  public projectConfigurations = new Subject<any>();
  projectConfigurations$ = this.projectConfigurations.asObservable();
  public allProjectConfig = new BehaviorSubject<any>(null);
  public allProjectConfig$ = this.allProjectConfig.asObservable();
  public allProject = [];

  private hasClientProjectConcurrent = new BehaviorSubject<boolean>(false);
  public hasClientProjectConcurrent$ = this.hasClientProjectConcurrent.asObservable();

  constructor(private communicatorServiceObject: CommunicatorService,
              public notificationService: NotificationService,
              public errorMessageService: ErrorMessageService) {
  }


  subscribeToLOBList() {
    if (this.lobList === undefined || this.lobList === null || this.lobList.length === 0) {
      this.commonLOBList();
    }
    return this.lobList;
  }

  public commonLOBList() {
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION + "/common/lob").subscribe(lob => {
      this.lobList = lob['results'];
      this.lobDetails.next(this.lobList);
    }, (error) => {
        const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
        if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
          this.notificationService.showError(manipulatedErrorMessage);
        }
    });
  }

  subscribeToRoleList() {
    if (this.roleList === undefined || this.roleList === null || this.roleList.length === 0) {
      this.getRoleList();
    }
    return this.roleList;
  }

  public getRoleList() {
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION + "/common/roles").subscribe(roles => {
      this.roleList = roles['results'];
      this.roleDetails.next(this.roleList);
    },(error) => {
      const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
      if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
        this.notificationService.showError(manipulatedErrorMessage);
      }
    });
  }


  subscribeToPOSChanges() {
    if (this.POSList === undefined || this.POSList === null || this.POSList.length === 0) {
      this.getPOSList();
    }
    return this.POSList;
  }

  /**
   * get list of POS.
   */
  public getPOSList() {
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION + "/common/pos").subscribe(POSData => {
      this.POSList = POSData['results'];
      for (const pos in this.POSList) {
        this.POSList[pos].code = this.POSList[pos].code;
        this.POSList[pos].place = this.POSList[pos].place;
        this.POSList[pos].text = this.POSList[pos].code + " " + "-" + " " + this.POSList[pos].place;
        this.POSList[pos].id = this.POSList[pos].id;
      }
      this.POSDetails.next(this.POSList);
    }, (error) => {
        const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
        if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
          this.notificationService.showError(manipulatedErrorMessage);
        }
    });
  }

  subscribeToNoteTypeChanges(projectId) {
    if (projectId in this.noteTypeProjectList) {
      this.noteTypeDetails.next(this.noteTypeProjectList[projectId]);
    } else {
      this.getNoteTypeList(projectId);
    }
  }

  /**
   * get list of Note Type List.
   */
  public getNoteTypeList(projectId) {
    let params = new HttpParams();
    if (projectId !== undefined && projectId !== null) {
      params = params.append('projectId', projectId);
    }
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION + "/note/types", params).subscribe(NoteTypeData => {
      this.noteTypeProjectList[projectId] = NoteTypeData['results'];
      this.noteTypeDetails.next(this.noteTypeProjectList[projectId]);
    }, (error) => {
        const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
        if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
          this.notificationService.showError(manipulatedErrorMessage);
        }
    });
  }

  subscribeToReviewerResponseList(projectId, role) {
    if (projectId in this.reviewerResponseList) {
      this.reviewerResponseListDetails.next(this.reviewerResponseList[projectId]);
    } else {
      this.getReviewerResponseList(projectId, role);
    }
  }


  /**
   * get list of reviewerResponseList
   */
  public getReviewerResponseList(projectId, purpose) {
    let params = new HttpParams();
    if (projectId !== undefined && projectId !== null) {
      params = params.append('projectId', projectId);
    }
    if (purpose !== undefined && purpose !== null) {
      params = params.append('purpose', purpose);
    }
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION + '/feedback/responses', params).subscribe(reviewerResponseListData => {
      this.reviewerResponseList[projectId] = reviewerResponseListData['results'];
      this.reviewerResponseListDetails.next(this.reviewerResponseList[projectId]);
    }, (error) => {
        const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
        if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
          this.notificationService.showError(manipulatedErrorMessage);
        }
    });
  }

  subscribeToFinalResponseList(projectId, role) {
    if (projectId in this.finalResponseList) {
      this.finalResponseListDetails.next(this.finalResponseList[projectId]);
    } else {
      this.getFinalResponseList(projectId, role);
    }
  }

  /**
   * get list of finalResponseList
   */
  public getFinalResponseList(projectId, purpose) {
    let params = new HttpParams();
    if (projectId !== undefined && projectId !== null) {
      params = params.append('projectId', projectId);
    }
    if (purpose !== undefined && purpose !== null) {
      params = params.append('purpose', purpose);
    }
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION + '/feedback/responses', params).subscribe(finalResponseListData => {
      this.finalResponseList[projectId] = finalResponseListData['results'];
      this.finalResponseListDetails.next(this.finalResponseList[projectId]);
    }, (error) => {
      if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
        this.notificationService.showError("Failed to process your request");
      } else {
        const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
        if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
          this.notificationService.showError(manipulatedErrorMessage);
        }
      }
    });
  }

  subscribeToSelectedProjectChanges(clientId, projectId) {
    if (clientId in this.allProject && projectId in this.allProject[clientId]) {
      this.projectConfigurations.next(this.allProject[clientId][projectId]);
    } else {
      this.getAllProjectConfigOfSelectedClient(clientId, projectId);
    }
  }

  getAllProjectConfigOfSelectedClient(clientId, projectId?) {
    if (clientId in this.allProject) {
      this.allProjectConfig.next(this.allProject[clientId]);
      if (projectId !== undefined && projectId !== null && projectId in this.allProject[clientId]) {
        this.projectConfigurations.next(this.allProject[clientId][projectId]);
      }
    }
    let params = new HttpParams();
    if (clientId !== undefined && clientId !== null) {
      params = params.append('clientId', clientId);
    }
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/projects/list", params).subscribe(data => {
        if (data !== undefined && data !== null) {
          if (!(clientId in this.allProject)) {
            this.allProject[clientId] = {};
          }
          for (const projects of data.results) {
            this.allProject[clientId][projects.id] = projects;
          }
          // this.allProject[clientId] = data.results;
          this.allProjectConfig.next(this.allProject[clientId]);
          if (projectId !== undefined && projectId !== null) {
            this.projectConfigurations.next(this.allProject[clientId][projectId]);
          }
          const hasConcurrentProject = data.results?.some((project:IProject)=>project?.type===Concurrent)
          this.changeClientProjectConcurrentStatus(hasConcurrentProject);

        }
      }, error => {
          this.changeClientProjectConcurrentStatus();
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
      }
    );
  }

  public changeClientProjectConcurrentStatus(value:boolean = false){
    this.hasClientProjectConcurrent.next(value);
  }

  get hasClientProjectConcurrentSubjectValue():boolean{
    return this.hasClientProjectConcurrent.value;
  }
}
