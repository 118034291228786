import { Injectable } from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import { ISignInError } from "./signin.enum";

@Injectable({
  providedIn: "root"
})
export class SigninService {
  private loginDetails = new Subject<any>();
  private signInError = new BehaviorSubject<ISignInError | null>(null);
  loginDetails$ = this.loginDetails.asObservable();
  

  constructor() { }

   shareloginData(data) {
    this.loginDetails.next(data);
   }

   publishError(error: ISignInError){
    this.signInError.next(error)
   }

   getSignInError$(){
    return this.signInError.asObservable()
   }
}
