import { Component,  Inject, OnInit, Optional } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-view-chart',
  templateUrl: './view-chart.component.html',
  styleUrls: ['./view-chart.component.scss']
})
export class ViewChartComponent implements OnInit {
  pdfUrl: any;

  constructor(public dialogRef: MatDialogRef<ViewChartComponent>,@Optional() @Inject(MAT_DIALOG_DATA) public data:any,private dom:DomSanitizer) { }

  ngOnInit(): void {
    this.pdfUrl= this.dom.bypassSecurityTrustResourceUrl(this.data.chartDetails.pdf)
  }

  closeChart(): void {
    this.dialogRef.close();
  }

}
