import { Injectable } from '@angular/core';
import { CommunicatorService } from './communicator.service';
import { API_CURRENT_VERSION, EndPointBaseUrl } from '../settings/settings';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResetAthenaChartsService {

  baseUrl: string = `${EndPointBaseUrl}${API_CURRENT_VERSION}`
  resetUrl: string = '/demo/chart-reset'

  constructor(private communicatorService: CommunicatorService) { 
  }

  resetChartsForAthenaDemo(){
    return this.communicatorService.updateData(`${this.baseUrl}${this.resetUrl}`, JSON.stringify({reset: true})).pipe(catchError(err => of(err)))
  }
}
