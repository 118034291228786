import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {API_CURRENT_VERSION, EndPointBaseUrl} from "../../../common/settings/settings";
import {CommunicatorService} from "../../../common/services/communicator.service";
import {NotificationService} from "../../../common/services/notification.service";
import {ErrorMessageService} from "../../../common/services/error-message.service";
import {IUser} from "../model/user";
import {UserService} from "../../user.service";

@Injectable({
  providedIn: "root"
})
export class ListUsersService {
  public supervisorList = Array<IUser>();
  public supervisorListDataForReassign = new BehaviorSubject<any>([IUser]);
  public supervisorListDataForReassign$ = this.supervisorListDataForReassign.asObservable();
  public userFullName: string;
  public reviewerList;
  public reviewerNameList = new Subject<any>();
  public reviewerNameList$ = this.reviewerNameList.asObservable();
  public passwordResetOfUser;
  public resetPasswordData = new Subject<any>();
  public resetPasswordData$ = this.resetPasswordData.asObservable();
  public resetPasswordConfirmation = new Subject<any>();
  public resetPasswordConfirmation$ = this.resetPasswordConfirmation.asObservable();
  public editAccessPermissions = [];
  public editAccessList = new Subject<any>();
  public editAccessList$ = this.editAccessList.asObservable();
  public editAccessPermissionsForNodesChecked = [];
  public editAccessListForNodesChecked = new Subject<any>();
  public editAccessListForNodesChecked$ = this.editAccessListForNodesChecked.asObservable();
  public newUserData = new Subject<any>();
  public newUserData$ = this.newUserData.asObservable();
  public updateUserData = new Subject<any>();
  public updateUserData$ = this.updateUserData.asObservable();

  constructor(private communicatorServiceObject: CommunicatorService,
              public notificationService: NotificationService,
              public errorMessageService: ErrorMessageService,
              public userService: UserService) {
  }

  getSupervisorListForReassign(isSupervisor, isActive, excludeRoleId, excludeId) {
    let queryParams = {};
    if (isSupervisor !== undefined && isSupervisor !== null) {
      queryParams['isSupervisor'] = isSupervisor;
    }
    if (isActive !== undefined && isActive !== null) {
      queryParams['isActive'] = isActive;
    }
    if (excludeRoleId !== undefined && excludeRoleId !== null) {
      queryParams['excludeRoleId'] = excludeRoleId;
    }
    if (excludeId !== undefined && excludeId !== null) {
      queryParams['excludeId'] = excludeId;
    }
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/users" + queryString).subscribe(data => {
        if (data !== undefined && data !== null && data.results.length > 0) {
          this.supervisorList = data.results;
          this.convertListData(this.supervisorList);
          this.supervisorListDataForReassign.next(this.supervisorList);
        }
      }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
      }
    );
  }

  convertListData(data) {
    if (data !== undefined && data !== null && data.length > 0) {
      // tslint:disable-next-line:prefer-for-of forin
      for (const dataItem in data) {
        if (data[dataItem].firstName !== undefined && data[dataItem].firstName !== null &&
          data[dataItem].lastName !== undefined && data[dataItem].lastName !== null) {
          data[dataItem].name = this.getFullName(data[dataItem]);
        }
        if (data[dataItem].supervisor !== undefined && data[dataItem].supervisor !== null) {
          data[dataItem].supervisorName = this.getFullName(data[dataItem].supervisor);
        }
      }
    }
    return data;
  }

  getFullName(userArray) {
    this.userFullName = "";
    if (userArray.firstName !== undefined && userArray.firstName !== null && userArray.firstName !== "") {
      this.userFullName = this.userFullName.concat((userArray.firstName).toString()) + " ";
    }
    if (userArray.lastName !== undefined && userArray.lastName !== null && userArray.lastName !== "") {
      this.userFullName = this.userFullName.concat((userArray.lastName).toString());
    }
    return this.userFullName;
  }

  activeInactiveUser(isUserActive, userId) {
    const userData = {};
    if (isUserActive === true) {
      userData['isActive'] = 0;
    } else {
      userData['isActive'] = 1;
    }
    this.communicatorServiceObject.patchData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/users/" + userId, JSON.stringify(userData)).subscribe(data => {
        if (data !== undefined && data !== null) {
          let successMessage = "";
          if (data.isActive === false) {
            successMessage = "User '" + data.username + "' has been successfully marked as an Inactive User.";
            this.notificationService.showSuccess(successMessage);
          } else if (data.isActive === true) {
            successMessage = "User '" + data.username + "' has been successfully marked as an Active User.";
            this.notificationService.showSuccess(successMessage);
          }
        }
      }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
      }
    );
  }

  getReviewerNameList(projectId, resourceId, chartId, masterExclusion) {
    let queryParams = {};
    if (projectId !== undefined && projectId !== null) {
      queryParams['projectId'] = projectId;
    }
    if (resourceId !== undefined && resourceId !== null) {
      queryParams['resourceId'] = resourceId;
    }
    if (chartId !== undefined && chartId !== null) {
      queryParams['chartId'] = chartId;
    }
    if (masterExclusion !== undefined && masterExclusion !== null) {
      queryParams['masterExclusion'] = masterExclusion;
    }
    queryParams = queryParams || {};
    let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/users" + queryString).subscribe(data => {
        if (data !== undefined && data !== null) {
          this.reviewerList = data.results;
          this.convertListData(this.reviewerList);
          this.reviewerNameList.next(this.reviewerList);
        }
      }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
      }
    );
  }

  resetUserPassword(userIds) {
    const userData = {};
    if (userIds !== undefined && userIds !== null && userIds.length > 0) {
      userData['userId'] = userIds;
    }
    this.communicatorServiceObject.updateData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/users/reset-password", JSON.stringify(userData)).subscribe(data => {
        if (data !== undefined && data !== null) {
          const successMessage = "Link to reset password has been successfully sent via email to the user";
          this.notificationService.showSuccess(successMessage);
          this.passwordResetOfUser = data;
          this.resetPasswordData.next(this.passwordResetOfUser);
        }
      }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
      }
    );
  }

  getResetPasswordConfirmation(data) {
    this.resetPasswordConfirmation.next(data);
  }

  getEditAccessPermissionList(permissionLevel, status) {
    if (this.editAccessPermissions !== undefined && this.editAccessPermissions !== null && this.editAccessPermissions.length > 0) {
      this.editAccessList.next(this.editAccessPermissions);
    } else {
      let queryParams = {};
      if (permissionLevel !== undefined && permissionLevel !== null) {
        queryParams['permissionLevel'] = permissionLevel;
      }
      if (status !== undefined && status !== null) {
        queryParams['status'] = status;
      }
      queryParams = queryParams || {};
      let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
      if (queryString !== "") {
        queryString = "?" + queryString;
      }
      this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
        "/common/resources/flat" + queryString).subscribe(data => {
          if (data !== undefined && data !== null) {
            this.editAccessPermissions = data.results;
            this.editAccessList.next(this.editAccessPermissions);
          }
        }, error => {
          if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
            this.notificationService.showError("Failed to process your request");
          } else {
            const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
            if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
              this.notificationService.showError(manipulatedErrorMessage);
            }
          }
        }
      );
    }
  }

  getEditAccessPermissionListForNodeChecked(permissionLevel, status) {
    if (this.editAccessPermissionsForNodesChecked !== undefined && this.editAccessPermissionsForNodesChecked !== null && this.editAccessPermissionsForNodesChecked.length > 0) {
      this.editAccessListForNodesChecked.next(this.editAccessPermissionsForNodesChecked);
    } else {
      let queryParams = {};
      if (permissionLevel !== undefined && permissionLevel !== null) {
        queryParams['permissionLevel'] = permissionLevel;
      }
      if (status !== undefined && status !== null) {
        queryParams['status'] = status;
      }
      queryParams = queryParams || {};
      let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
      if (queryString !== "") {
        queryString = "?" + queryString;
      }
      this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
        "/common/resources" + queryString).subscribe(data => {
          if (data !== undefined && data !== null) {
            this.editAccessPermissionsForNodesChecked = data.results;
            this.editAccessListForNodesChecked.next(this.editAccessPermissionsForNodesChecked);
          }
        }, error => {
          if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
            this.notificationService.showError("Failed to process your request");
          } else {
            const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
            if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
              this.notificationService.showError(manipulatedErrorMessage);
            }
          }
        }
      );
    }
  }
}


