import {Component, Inject, OnInit, Optional, ViewChild} from "@angular/core";
import {MultiSelectComponent} from "@syncfusion/ej2-angular-dropdowns";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import { BulkUpdateDialogComponent } from "../../bulk-update-dialog/bulk-update-dialog.component";
import { CommunicatorService } from "src/app/common/services/communicator.service";
import { DataService } from "src/app/common/services/data.service";
import { CoderRole, QAL1Role, QAL2Role, QAL3Role } from "src/app/common/settings/settings";
import { ActivatedRoute } from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IBulkUpdateData} from "../../data/bulk-update-data";

@Component({
  selector: 'app-bulkupdate-dialog',
  templateUrl: './bulkupdate-dialog.component.html',
  styleUrls: ['./bulkupdate-dialog.component.scss']
})
export class BulkupdateDialogComponent implements OnInit {
  public codeStatusList = [];
  public codeStatusFields: object = {value: "id", text: "name"};
  public codeCommentFields: object = {value: "id", text: "comment"};
  public auditStatusList = [];
  public l1auditStatusField: object = {value: "id", text: "name"};
  public l1auditCommentField: object = {value: "id", text: "comment"};
  public projectType: number;
  @ViewChild("auditCommentL1BulkUpdate")
  public auditCommentL1Objs: MultiSelectComponent;
  @ViewChild("codeCommentBulkUpdate")
  public codeCommentObjs: MultiSelectComponent;
  public checkboxMode: string;
  public generalSortOrder = "Ascending";
  public codeStatus: string;
  public codeComment = [];
  public auditStatus: string;
  public auditComment: string;
  public isCommentMandatory: boolean;
  public hccProfileCategory: number;
  public finalCategory: number;
  public gridToolTipContent = "";
  public enableToolTipCSS = {class: "enableToolTip"};
  public roleId: number;
  public coderRoleId = CoderRole;
  public QA1RoleId = QAL1Role;
  public QA2RoleId = QAL2Role;
  public QA3RoleId = QAL3Role;
  public bulkUpdateForm: FormGroup;
  public displayCoderStatus: string;

  constructor(private dialogRef: MatDialogRef<BulkupdateDialogComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: IBulkUpdateData,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.checkboxMode = "CheckBox";
    if (this.data) {
      this.roleId = this.data.role;
      this.codeStatusList = this.data.statuses;
    }
    this.bulkUpdateForm = this.buildBulkUpdateForm();
  }

  buildBulkUpdateForm(): FormGroup {
    return this.formBuilder.group({
      coderStatus: new FormControl(null, this.roleId === CoderRole ? Validators.required : []),
      coderComments: new FormControl([]),
      l1AuditStatus: new FormControl(null, this.roleId === QAL1Role ? Validators.required : []),
      l1AuditComments: new FormControl([]),
      l2AuditStatus: new FormControl(null, this.roleId === QAL2Role ? Validators.required : []),
      l2AuditComments: new FormControl([]),
      l3AuditStatus: new FormControl(null, this.roleId === QAL3Role ? Validators.required : []),
      l3AuditComments: new FormControl([]),
    });
  }

  getCodeStatus(event) {
    this.codeCommentObjs.dataSource = event.itemData.codeComments;
    this.codeCommentObjs.value = [];
    this.codeStatus = event.itemData.id;
    this.isCommentMandatory = event.itemData.isCommentMandatory;
    this.hccProfileCategory = event.itemData.statusCategory;
    this.finalCategory = event.itemData.finalCategory;
    this.displayCoderStatus = event.itemData.name;
    if (
      this.isCommentMandatory !== undefined &&
      this.isCommentMandatory !== null &&
      this.isCommentMandatory === true
    ) {
      this.bulkUpdateForm.get('coderComments').setValidators(Validators.required);
    } else {
      this.bulkUpdateForm.get('coderComments').clearValidators();
    }
    this.bulkUpdateForm.get('coderComments').updateValueAndValidity();
  }

  getCodeComment(event) {
    this.codeComment = event.value;
  }

  getLvl1AuditStatus(event) {
    this.auditCommentL1Objs.dataSource = event.itemData.codeComments;
    this.auditCommentL1Objs.value = [];
    this.auditStatus = event.itemData.id;
    this.isCommentMandatory = event.itemData.isCommentMandatory;
    this.hccProfileCategory = event.itemData.statusCategory;
    this.finalCategory = event.itemData.finalCategory;
    this.displayCoderStatus = event.itemData.name;
    if (
      this.isCommentMandatory !== undefined &&
      this.isCommentMandatory !== null &&
      this.isCommentMandatory === true
    ) {
      this.bulkUpdateForm.get('l1AuditComments').setValidators(Validators.required);
    } else {
      this.bulkUpdateForm.get('l1AuditComments').clearValidators();
    }
    this.bulkUpdateForm.get('l1AuditComments').updateValueAndValidity();
  }

  getLvl1AuditComment(event) {
    this.auditComment = event.value;
  }

  getLvl2AuditStatus(event) {
    this.auditCommentL1Objs.dataSource = event.itemData.codeComments;
    this.auditCommentL1Objs.value = [];
    this.auditStatus = event.itemData.id;
    this.isCommentMandatory = event.itemData.isCommentMandatory;
    this.hccProfileCategory = event.itemData.statusCategory;
    this.finalCategory = event.itemData.finalCategory;
    this.displayCoderStatus = event.itemData.name;
    if (
      this.isCommentMandatory !== undefined &&
      this.isCommentMandatory !== null &&
      this.isCommentMandatory === true
    ) {
      this.bulkUpdateForm.get('l2AuditComments').setValidators(Validators.required);
    } else {
      this.bulkUpdateForm.get('l2AuditComments').clearValidators();
    }
    this.bulkUpdateForm.get('l2AuditComments').updateValueAndValidity();
  }

  getLvl2AuditComment(event) {
    this.auditComment = event.value;
  }


  getLvl3AuditStatus(event) {
    this.auditCommentL1Objs.dataSource = event.itemData.codeComments;
    this.auditCommentL1Objs.value = [];
    this.auditStatus = event.itemData.id;
    this.isCommentMandatory = event.itemData.isCommentMandatory;
    this.hccProfileCategory = event.itemData.statusCategory;
    this.finalCategory = event.itemData.finalCategory;
    this.displayCoderStatus = event.itemData.name;
    if (
      this.isCommentMandatory !== undefined &&
      this.isCommentMandatory !== null &&
      this.isCommentMandatory === true
    ) {
      this.bulkUpdateForm.get('l3AuditComments').setValidators(Validators.required);
    } else {
      this.bulkUpdateForm.get('l3AuditComments').clearValidators();
    }
    this.bulkUpdateForm.get('l3AuditComments').updateValueAndValidity();
  }

  getLvl3AuditComment(event) {
    this.auditComment = event.value;
  }

  closeBulkUpdatePopup() {
    this.dialogRef.close();
  }

  applyBulkUpdate() {
    if (this.bulkUpdateForm.valid) {
      if (this.roleId === CoderRole) {
        this.dialogRef.close({
          coderStatus: this.codeStatus,
          coderComments: this.codeComment,
          isCommentMandatory: this.isCommentMandatory,
          close: false,
          statusCategory: this.hccProfileCategory,
          finalCategory: this.finalCategory,
          displayCoderStatus: this.displayCoderStatus
        });
      } else {
        this.dialogRef.close({
          auditStatus: this.auditStatus,
          auditComments: this.auditComment,
          isCommentMandatory: this.isCommentMandatory,
          close: false,
          statusCategory: this.hccProfileCategory,
          finalCategory: this.finalCategory,
          displayCoderStatus: this.displayCoderStatus
        });
      }
    } else {
      this.bulkUpdateForm.markAllAsTouched();
    }
  }

  showToolTipOnCellsHavingRequiredClassAndSomeContentsOrCancelIt(args) {
    if (args.target !== undefined && args.target !== null) {
      if (args.target.classList.contains(this.enableToolTipCSS.class)) {
        if (args.target.ej2_instances !== undefined && args.target.ej2_instances !== null &&
          args.target.ej2_instances.length > 0 && args.target.ej2_instances[0].value !== "" &&
          args.target.ej2_instances[0].value !== undefined && args.target.ej2_instances[0].value !== null) {
          if (Array.isArray(args.target.ej2_instances[0].value)) {
            if (args.target.ej2_instances[0].value.length === 0) {
              args.cancel = true;
            } else {
              this.gridToolTipContent = args.target.ej2_instances[0].text;
            }
          } else {
            this.gridToolTipContent = args.target.ej2_instances[0].value;
          }
        }
        else {
          args.cancel = true;
        }
      } else {
        args.cancel = true;
      }
    }
  }
}
