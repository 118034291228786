import { Injectable, OnInit } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import {AuthService} from "./auth.service";
import {Observable, of} from "rxjs";
import { catchError, map, tap} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CookieService} from "ngx-cookie";
import { SigninService } from "src/app/session/signin/signin.service";
import { ISignInError } from "src/app/session/signin/signin.enum";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class AuthGaurdService implements OnInit, CanActivate {


  error: ISignInError | null = null
  constructor(private http: HttpClient, private cookieService: CookieService,
              public auth: AuthService, public router: Router, private signInService: SigninService) { }

  ngOnInit(): void {
    
  }
  /**
   * Route activation check - ONLY allow if user is authenticated
   * Otherwise redirect to sign-in
   * protect all routes which are ONLY suppose to be accessible if user is login
   * with it
   */
  
  canActivate(): Observable<boolean> {
    if (!this.auth.authenticated) {
      const csrftoken = this.cookieService.get(environment.CSRF_COOKIE_NAME);
      const httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          // "Access-Control-Allow-Credentials": "true",
          Accept: "application/json",
          "X-CSRFToken": csrftoken
        }),
        withCredentials: true
      };
      return this.http.get("", httpOptions)
    .pipe(
        map(e => {
            return !!e;
        }),
        catchError((err) => {
            if (Number(localStorage.getItem('isExt'))) {
                return this.signInService.getSignInError$().pipe(
                    tap(error => {
                      if(error){
                        this.router.navigateByUrl(`error?msg=${error.message?.replace(/is\s*Active,\s*/, '')}`)
                      } else {
                        this.router.navigateByUrl(`error?msg=Authentication Error!`)
                      };
                    }),
                    map(() => false)
                );
            } else {
                this.router.navigate(["/session/signin"]);
                return of(false);
            }
        })
    );
    } else {
      return of(true);
    }
  }
}
