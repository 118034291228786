<div class="h-100 w-100 d-flex flex-column align-items-center justify-content-center gap-3">
    <svg fill="#000000" width="65px" height="65px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15 21.063v-15.063c0-0.563 0.438-1 1-1s1 0.438 1 1v15.063h-2zM15 23.031h2v1.875h-2v-1.875zM0 16c0-8.844 7.156-16 16-16s16 7.156 16 16-7.156 16-16 16-16-7.156-16-16zM30.031 16c0-7.719-6.313-14-14.031-14s-14 6.281-14 14 6.281 14 14 14 14.031-6.281 14.031-14z">
        </path>
    </svg>
    <div class="d-flex gap-1 flex-column align-items-center">
        <p class="m-0 font-size-lg">Something went wrong!</p>
        <p class="m-0" *ngIf="(error$ | async) as errorMessage">{{errorMessage.error}}</p>
        <p class="m-0" *ngIf="(error$ | async) as errorMessage">{{errorMessage.msg}}</p>

    </div>
</div>