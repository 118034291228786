export class IUser {
  approvalStatus: number;
  createdDate: string;
  dateJoined: string;
  deleted: boolean;
  email: string;
  firstName: string;
  id: number;
  isActive: boolean;
  isStaff: boolean;
  isSuperuser: boolean;
  isSupervisor: boolean;
  lastLogin: string;
  lastName: string;
  license: string;
  loadId: number;
  phoneNumber: number;
  supervisor: IUser;
  timezone: string;
  typeOfUser: number;
  username: string;
  clients?: any;
  role: number;
}
