import { Injectable } from '@angular/core';
import { BehaviorSubject, of, ReplaySubject, Subject } from 'rxjs';
import { CommunicatorService } from 'src/app/common/services/communicator.service';
import { API_CURRENT_VERSION, EndPointBaseUrl } from 'src/app/common/settings/settings';
import { IAthenaExtensionValues } from '../types/athenaValues';

@Injectable({
  providedIn: 'root'
})
export class ExtensionService {

  scrappedData = new ReplaySubject<IAthenaExtensionValues>(null)
  constructor(private communicatorService: CommunicatorService) { }

  async getMemberId(patient_id: number, projectId: number, clientId: number) {
    return await this.communicatorService.getData(EndPointBaseUrl + API_CURRENT_VERSION + '/members/get_member_detail_by_patient_id/', { patient_id, projectId, clientId }).toPromise()
  }

  async getEHRRole(ehr_practice_id: number) {
    return await this.communicatorService.getData(EndPointBaseUrl + API_CURRENT_VERSION + '/projects/get_ehr_role/', { ehr_practice_id }).toPromise()
  }

  publishScrappedData(dataFromExt: IAthenaExtensionValues) {
    this.scrappedData.next(dataFromExt)
  }

  subscribeToScrappedData() {
    return this.scrappedData.asObservable()
  }

  sendRiskGapsToExt(numberOfRiskGaps: number) {
    const message = ({
      type: 'risk-gap',
      value: numberOfRiskGaps,
    });

    if(!isNaN(numberOfRiskGaps)) window.parent.postMessage(message, '*');
  }

  sendQueriesToExt(numberOfQueries: number) {
    const message = ({
      type: 'queries',
      value: numberOfQueries,
    });
    window.parent.postMessage(message, '*');
  }

  sendCompleteStatusToExt() {
    const message = {
      type: 'risk-gap-completed'
    }
    window.parent.postMessage(message, '*');
  }

  sendPatientNotFound() {
    const message = {
      type: 'user-not-found'
    }
    window.parent.postMessage(message, '*');
  }

  sendUserLogout() {
    const message = {
      type: 'user-logout'
    }
    window.parent.postMessage(message, '*');
  }

  sendCdiCompleteStatusToExt(){
    const message = {
      type: 'cdi-risk-gap-completed'
    }
    window.parent.postMessage(message, '*');
  }

  sendIsProviderStatusToExt(is_provider: boolean){
    const message = ({
      type: 'set-role',
      value: {
        is_provider      
      }
    });
    window.parent.postMessage(message, '*');
  }

  sendProviderRiskGapSubmitted(){
    const message = ({
      type: 'risk-gap-submitted'
    })
    window.parent.postMessage(message, '*');
  }

  sendCDIRiskGapSubmitted(){
    const message = ({
      type: 'cdi-risk-gap-submitted'
    })
    window.parent.postMessage(message, '*');
  }

  sendRouteMessage(patientId: number){
    const message = {
      type: 'route-to-chart',
      value: patientId
    }
    window.parent.postMessage(message, '*');
  }

}
