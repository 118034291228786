import { AdminLayoutComponent, AuthLayoutComponent } from "./core";
import { AuthGaurdService as AuthGaurd } from "./common/services/auth-gaurd.service";

import { Routes } from "@angular/router";
import { CodeCommentsSelectDialogComponent } from "./code-comments-select-dialog/code-comments-select-dialog.component";
import { BulkupdateDialogComponent } from "./coding/coding-v2/bulkupdate-dialog/bulkupdate-dialog.component";
import { ErrorComponent } from "./error/error.component";
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";

export const AppRoutes: Routes = [
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./dashboard/dashboard.module").then(m => m.DashboardModule)
      }, {
        path: "users",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./user/user.module").then(m => m.UserModule)
      },
      {
        path: "coding",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./coding/coding.module").then(m => m.CodingModule)
      },
      {
        path:"coding-concurrent",
        canActivate:[AuthGaurd],
        loadChildren: ()=>
          import("./coding-concurrent/coding-concurrent.module").then(m=>m.CodingConcurrentModule)
      },
      {
        path: "client",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./client/client.module").then(m => m.ClientModule)
      },
      {
        path: "",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./queue/queue.module").then(m => m.QueueModule)
      },
      {
        path: "",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./education/education.module").then(m => m.EducationModule)
      },
      {
        path: "project",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./project/project.module").then(m => m.ProjectModule)
      },
      {
        path: "reports",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./reports/reports.module").then(m => m.ReportsModule)
      },
      {
        path: "provider",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./provider/provider.module").then(m => m.ProviderModule)
      },
      {
        path: "concurrent",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./concurrent/concurrent.module").then(m => m.ConcurrentModule)
      },
      {
        path: "guidelines",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./guidelines/guidelines.module").then(m => m.GuidelinesModule)
      },
      {
        path: "education",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./education/education.module").then(m => m.EducationModule)
      },
      {
        path: "alerts",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./alerts/alerts.module").then(m => m.AlertsModule)
      },
      {
        path: "coding-profile",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./coding-profile/coding-profile.module").then(m => m.CodingProfileModule)
      },
      {
        path: "chart-repository",
        canActivate: [AuthGaurd],
        loadChildren: () =>
          import("./chart-repository/chart-repository.module").then(m => m.ChartRepositoryModule)
      },
    ]
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "session",
        loadChildren: () =>
          import("./session/session.module").then(m => m.SessionModule)
      }
    ]
  },
  {
    path: 'select-comment',
    component: CodeCommentsSelectDialogComponent
  },
  {
    path: 'bulk-update',
    component: BulkupdateDialogComponent
  },
  {
    path: 'athena-one',
    loadChildren: () => import("./athena-one-integration/athena-one-integration.module").then(m => m.AthenaOneIntegrationModule)
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: "**",
    component: PageNotFoundComponent,
  },
];
