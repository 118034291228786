import {Component, Inject, OnInit, Optional, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  API_CURRENT_VERSION,
  ClientRelationshipManager, EndPointBaseUrl,
  ProductOwner,
  SuperUser,
  SystemAdministrator
} from "../../../../common/settings/settings";
import {Subscription} from "rxjs";
import {ListUsersService} from "../../list-users/list-users.service";
import {ModifyUserService} from "../modify-user.service";
import {IUser} from "../../model/user";
import {DataManager, Query} from "@syncfusion/ej2-data";
import {SearchAdaptorService} from "../../../../common/services/search-adaptor.service";
import {DropDownListComponent} from "@syncfusion/ej2-angular-dropdowns";

@Component({
  selector: "app-reassign-supervisor-dialog",
  templateUrl: "./reassign-supervisor-dialog.component.html",
  styleUrls: ["./reassign-supervisor-dialog.component.scss"]
})
export class ReassignSupervisorDialogComponent implements OnInit {
  public supervisor = "Supervisor";
  public generalSortOrder = "Ascending";
  public height = "220px";
  public supervisor$: Subscription;
  public selectedSupervisor: number;
  public fields: object = {text: "name", value: "id"};
  public supervisorFields: object = {text: "name", value: "id"};
  public supervisorList: DataManager = new DataManager({
    url: EndPointBaseUrl + API_CURRENT_VERSION + "/users/filter",
    adaptor: new SearchAdaptorService(),
    crossDomain: true
  });
  public supervisorQuery: Query;
  @ViewChild("supervisorDropdown")
  public supervisorDropdown: DropDownListComponent;


  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data,
              private dialogRef: MatDialogRef<ReassignSupervisorDialogComponent>,
              public listUsersService: ListUsersService,
              public modifyUserService: ModifyUserService) { }

  ngOnInit(): void {
    this.getSupervisorList();
  }

  getSupervisorList() {
    this.supervisorQuery = new Query();
    const roleIds = [];
    roleIds.push(ProductOwner, ClientRelationshipManager, SuperUser);
    this.supervisorQuery.addParams("isSupervisor", String(1)).addParams("isActive", String(1))
      .addParams("excludeRoleId", String(roleIds)).addParams("excludeId", String(this.data.selectedUser))
      .addParams("ordering", String("first_name,last_name")).take(20);
  }

  onCancel() {
    this.modifyUserService.modifySupervisor.next({action: "change.supervisor.checkbox", value: false});
    this.dialogRef.close();
  }

  getSelectedSupervisor(event) {
    if (event && event.itemData !== undefined && event.itemData !== null &&
      event.itemData.id !== undefined && event.itemData.id !== null) {
      this.selectedSupervisor = event.itemData.id;
    }
  }

  reassignSupervisor(event) {
    // todo close the pop up if the assignment is successful otherwise
    if (event !== undefined && event !== null) {
      if (this.selectedSupervisor !== undefined && this.selectedSupervisor !== null) {
        this.modifyUserService.reassignSupervisor(this.data.selectedUser, "reassign-supervisor" , this.selectedSupervisor );
        this.modifyUserService.newSupervisorData$.subscribe(data => {
          if (data !== undefined && data !== null) {
            this.modifyUserService.modifySupervisor.next({action: "change.supervisor.checkbox", value: true});
            this.dialogRef.close();
          }
        });
      }
    }
  }

  searchSupervisors(event) {
    if (event !== undefined && event !== null) {
      if (event.text !== undefined && event.text !== null && event.text !== "") {
        this.supervisorDropdown.noRecordsTemplate = "No Records To Display";
      }
    }
  }
}
