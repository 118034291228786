import {
  AdminLayoutComponent,
  AuthLayoutComponent,
  HeaderComponent,
  LayoutComponent
} from "./core";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import { MultilevelMenuService, NgMaterialMultilevelMenuModule } from "ng-material-multilevel-menu";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { AgmCoreModule } from "@agm/core";
import { AppComponent } from "./app.component";
import { AppRoutes } from "./app.routing";
import { BidiModule } from "@angular/cdk/bidi";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule } from "@angular/forms";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import {ErrorHandler, NgModule} from "@angular/core";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { RouterModule } from "@angular/router";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {GridModule} from "@syncfusion/ej2-angular-grids";
import {DropDownListModule, ListBoxAllModule} from "@syncfusion/ej2-angular-dropdowns";
import {ButtonModule, CheckBoxModule} from "@syncfusion/ej2-angular-buttons";
import {MatGridListModule} from "@angular/material/grid-list";
import {CookieService, CookieModule} from "ngx-cookie";
import {SplitterModule} from "@syncfusion/ej2-angular-layouts";
import {MatTableModule} from "@angular/material/table";
import {MatRadioModule} from "@angular/material/radio";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatDialogModule} from "@angular/material/dialog";
import {TooltipModule} from "@syncfusion/ej2-angular-popups";
import {MatTooltipModule} from "@angular/material/tooltip";
import {CommonModule, DatePipe} from "@angular/common";
import {MenuModule} from "@syncfusion/ej2-angular-navigations";
import {ReactiveFormsModule} from "@angular/forms";
import {ServerErrorInterceptor} from "./common/services/server-error.interceptor";
import {GlobalErrorHandler} from "./common/services/global-error-handler";
import {MatBadgeModule} from "@angular/material/badge";
import { CommonDialogComponent } from "./common/component/common-dialog/common-dialog.component";
import { MatPasswordStrengthModule } from "@angular-material-extensions/password-strength";
import {SessionExpiredComponent} from "./session-expired/session-expired.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {NgIdleModule} from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ScheduledMaintenanceComponent } from './scheduled-maintenance/scheduled-maintenance.component';
import { SystemOfflineComponent } from './system-offline/system-offline.component';
import { SentenceReplaceDialogComponent } from './sentence-replace-dialog/sentence-replace-dialog.component';
import { ViewChartComponent } from "./core/header/view-chart/view-chart.component";
import { NgDragDropModule } from 'ng-drag-drop';
import { ShowMeatListDialogComponent } from './show-meat-list-dialog/show-meat-list-dialog.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { InterceptorService } from "./common/services/interceptor.service";
import { CodeCommentsSelectDialogComponent } from './code-comments-select-dialog/code-comments-select-dialog.component';
import { MultiSelectAllModule } from "@syncfusion/ej2-angular-dropdowns";
import { ShareInputRestrictionModule } from "./share-input-restriction/share-input-restriction.module";
import { ErrorComponent } from './error/error.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AdminLayoutComponent,
    LayoutComponent,
    AuthLayoutComponent,
    CommonDialogComponent,
    SessionExpiredComponent,
    ScheduledMaintenanceComponent,
    SystemOfflineComponent,
    SentenceReplaceDialogComponent,
    ViewChartComponent,
    ShowMeatListDialogComponent,
    CodeCommentsSelectDialogComponent,
    ErrorComponent,
    PageNotFoundComponent,
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(AppRoutes, {relativeLinkResolution: "legacy"}),
        FormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        LoadingBarRouterModule,
        MatSidenavModule,
        MatCardModule,
        MatMenuModule,
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        MatToolbarModule,
        MatTabsModule,
        MatListModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatProgressBarModule,
        FlexLayoutModule,
        BidiModule,
        AgmCoreModule.forRoot({
            apiKey: "YOUR_API_KEY"
        }),
        PerfectScrollbarModule,
        NgMaterialMultilevelMenuModule,
        GridModule,
        DropDownListModule,
        ButtonModule,
        MatGridListModule,
        CookieModule.forRoot(),
        SplitterModule,
        MatTableModule,
        MatRadioModule,
        MatSnackBarModule,
        CheckBoxModule,
        MatDialogModule,
        TooltipModule,
        MatTooltipModule,
        CommonModule,
        MenuModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatSnackBarModule,
        MatBadgeModule,
        MatPasswordStrengthModule,
        DragDropModule,
        NgIdleModule.forRoot(),
      NgIdleKeepaliveModule.forRoot(),
      NgDragDropModule.forRoot(),
      NgxExtendedPdfViewerModule,
      MultiSelectAllModule,
      ListBoxAllModule,
      ShareInputRestrictionModule
    ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: 
      InterceptorService, multi: true },
    DatePipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }, MultilevelMenuService, CookieService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
