<ejs-tooltip [content]='gridToolTipContent' target=".enableToolTip"
             (beforeRender)="showToolTipOnCellsHavingRequiredClassAndSomeContentsOrCancelIt($event)">
  <form *ngIf="bulkUpdateForm"  [formGroup]="bulkUpdateForm" autocomplete="off">
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane"
         cdkDragBoundary=".cdk-overlay-container" cdkDragHandle class="dialog-drag">
      <div fxLayout="row wrap">
        <h2 class="bulk-update-heading">Bulk Update</h2>
        <div fxFlex></div>
        <span>
          <button mat-icon-button matTooltip="Click here to close the popup"
                  (click)="closeBulkUpdatePopup()">
           <mat-icon>close</mat-icon>
          </button>
        </span>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" *ngIf="roleId === coderRoleId">
      <div fxLayout="column">
        <div fxLayout="column">
          <ejs-dropdownlist width="220" placeholder='Code Status*' popupHeight='220px'
                            [dataSource]='codeStatusList' [fields]='codeStatusFields' formControlName="coderStatus"
                            [sortOrder]="generalSortOrder" (select)="getCodeStatus($event)"></ejs-dropdownlist>
        </div>
        <div fxLayout="row">
          <mat-error
            *ngIf="bulkUpdateForm.get('coderStatus').touched && bulkUpdateForm.get('coderStatus').hasError('required')">
            Required
          </mat-error>
        </div>
      </div>
      <div fxLayout="column">
        <div fxLayout="column">
          <ejs-multiselect [sortOrder]="generalSortOrder" #codeCommentBulkUpdate [allowFiltering]='true'
                           placeholder='Code Comment' width="320" popupWidth='330'
                           [fields]='codeCommentFields' [showDropDownIcon]='true' showSelectAll='true'
                           [mode]='checkboxMode' class="enableToolTip"
                           (change)="getCodeComment($event)" formControlName="coderComments"></ejs-multiselect>
        </div>
        <div fxLayout="row">
          <mat-error *ngIf="bulkUpdateForm.get('coderComments').touched && bulkUpdateForm.get('coderComments').hasError('required')">
            Required
          </mat-error>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" *ngIf="roleId === QA1RoleId">
      <div fxLayout="column">
        <div fxLayout="column">
          <ejs-dropdownlist width="220" placeholder="Lvl1 Audit Status*" popupHeight='220px'
                            [dataSource]='codeStatusList' [fields]='l1auditStatusField'
                            [sortOrder]="generalSortOrder"
                            (select)="getLvl1AuditStatus($event)" formControlName="l1AuditStatus"></ejs-dropdownlist>
        </div>
        <div fxLayout="row">
          <mat-error
            *ngIf="bulkUpdateForm.get('l1AuditStatus').touched && bulkUpdateForm.get('l1AuditStatus').hasError('required')">
            Required
          </mat-error>
        </div>
      </div>
      <div fxLayout="column">
        <div fxLayout="column">
          <ejs-multiselect [sortOrder]="generalSortOrder" #auditCommentL1BulkUpdate [allowFiltering]='true'
                           placeholder='Lvl1 Audit Comment' width="300"
                           [fields]='l1auditCommentField' [showDropDownIcon]='true' showSelectAll='true'
                           [mode]='checkboxMode' class="enableToolTip"
                           (change)="getLvl1AuditComment($event)" formControlName="l1AuditComments"></ejs-multiselect>
        </div>
        <div fxLayout="row">
          <mat-error *ngIf="bulkUpdateForm.get('l1AuditComments').hasError('required')">
            Required
          </mat-error>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" *ngIf="roleId === QA2RoleId">
      <div fxLayout="column">
        <div fxLayout="column">
          <ejs-dropdownlist width="220" placeholder="Lvl2 Audit Status*" popupHeight='220px'
                            formControlName="l2AuditStatus"
                            [dataSource]='codeStatusList' [fields]='l1auditStatusField'
                            [sortOrder]="generalSortOrder"
                            (select)="getLvl2AuditStatus($event)"></ejs-dropdownlist>
        </div>
        <div fxLayout="row">
          <mat-error
            *ngIf="bulkUpdateForm.get('l2AuditStatus').touched && bulkUpdateForm.get('l2AuditStatus').hasError('required')">
            Required
          </mat-error>
        </div>
      </div>
      <div fxLayout="column">
        <div fxLayout="column">
          <ejs-multiselect [sortOrder]="generalSortOrder" #auditCommentL1BulkUpdate [allowFiltering]='true'
                           placeholder='Lvl2 Audit Comment' width="300"
                           [fields]='l1auditCommentField' [showDropDownIcon]='true' showSelectAll='true'
                           [mode]='checkboxMode' class="enableToolTip" (change)="getLvl2AuditComment($event)"
                           formControlName="l2AuditComments"></ejs-multiselect>

        </div>
        <div fxLayout="row">
          <mat-error *ngIf="bulkUpdateForm.get('l2AuditComments').hasError('required')">
            Required
          </mat-error>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" *ngIf="roleId === QA3RoleId">
      <div fxLayout="column">
        <div fxLayout="column">
          <ejs-dropdownlist width="220" placeholder="Lvl3 Audit Status*" popupHeight='220px'
                            formControlName="l3AuditStatus"
                            [dataSource]='codeStatusList' [fields]='l1auditStatusField'
                            [sortOrder]="generalSortOrder"
                            (select)="getLvl3AuditStatus($event)"></ejs-dropdownlist>
        </div>
        <div fxLayout="row">
          <mat-error
            *ngIf="bulkUpdateForm.get('l3AuditStatus').touched && bulkUpdateForm.get('l3AuditStatus').hasError('required')">
            Required
          </mat-error>
        </div>
      </div>
      <div fxLayout="column">
        <div fxLayout="column">
          <ejs-multiselect [sortOrder]="generalSortOrder" #auditCommentL1BulkUpdate [allowFiltering]='true'
                           placeholder='Lvl3 Audit Comment' width="300"
                           [fields]='l1auditCommentField' [showDropDownIcon]='true' showSelectAll='true'
                           [mode]='checkboxMode' class="enableToolTip" (change)="getLvl3AuditComment($event)"
                           formControlName="l3AuditComments"></ejs-multiselect>

        </div>
        <div fxLayout="row">
          <mat-error *ngIf="bulkUpdateForm.get('l3AuditComments').hasError('required')">
            Required
          </mat-error>
        </div>
      </div>
    </div>
    <br>
    <div fxLayout="row wrap" fxLayoutGap="3px" fxLayoutAlign="end">
      <button mat-raised-button color="primary" (click)="applyBulkUpdate()">Apply</button>
    </div>
  </form>
</ejs-tooltip>
