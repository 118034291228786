import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appInputRestriction]'
})
export class InputRestrictionDirective {

  constructor(private el:ElementRef) { }

  @HostListener('input', ['$event']) onInput(event) {
    const inputValue = event.event.target.value;
    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, '');
    event.event.target.value = sanitizedValue;
    // event.preventDefault();
  }

}
